<template >
  <!-- vue实例外创建 -->
  <div class="app"><router-view></router-view></div>
</template>
<!-- 调用   <mycom></mycom> -->
<script>
export default {
  name: "App",
  mounted() {
    if (this._isMobile()) {
      // 跳转至手机端路由
      // alert("手机端");
      // this.$router.replace("/phoneJiameng");
      this.$router.replace("/phoneHome");
    } else {
      // 跳转至 pc 端路由
      // alert("pc端");
      this.$router.replace("/pcHome");
      // this.$router.replace("/pcHome");
    }
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
};
</script>
<style lang="less" scoped>
</style>