<template >
  <!-- vue实例外创建 -->
  <div class="jiamengBox">
    <div class="header">
      <div class="layout">
        <div class="layout-logo">
          <img class="layout-logo-image" src="@/assets/1/logo.png" alt="" />
        </div>
        <div class="header_icon" @click="drawer = true">
          <i class="el-icon-s-unfold"></i>
        </div>
      </div>
    </div>
    <div class="section1">
      <div class="section1_box">
        <div class="section1_title">柚子自助洗车，产品称心，服务如意。</div>
        <div class="section1_text">
          公司提供建店选址建议、装修方案、装修指导、耗材配送
          开业前远程指导安装培训、终身系统技术支持及维护
        </div>
        <el-button class="section1_btn"> 前往小程序 </el-button>
      </div>
      <div class="section1_img">
        <img src="@/assets/jiameng1_bg.png" alt="" />
      </div>
    </div>
    <div class="jiameng">
      <div class="jiameng_title">
        <span>柚子共享洗车加盟费用-</span>
        <span>开业限时优惠价</span>
      </div>

      <div class="jiameng_image" @click="openImageClick">
        <img src="https://carcdn.asred.cn/image/jiamengbiao.jpg" alt="" />
      </div>
    </div>

    <el-drawer :visible.sync="drawer" :direction="direction" :size="'100%'">
      <ul class="drawer_ul">
        <li class="drawer_li">
          <a href="#section1" @click="phoneDraClick">网站首页</a>
        </li>
        <li class="drawer_li">
          <a href="#section4" @click="phoneDraClick">公司简介</a>
        </li>
        <li class="drawer_li" @click="jiamengClick"><a>加盟合作</a></li>
        <li class="drawer_li">
          <a href="#section7" @click="phoneDraClick">联系我们</a>
        </li>
        <li class="drawer_li">
          <a href="#section6" @click="phoneDraClick">新闻中心</a>
        </li>
      </ul>
    </el-drawer>
  </div>
</template>

<script>
// import Vue from "vue";
import { ImagePreview } from "vant";
import "vant/lib/index.css";
export default {
  components: { ImagePreview },
  data() {
    return { drawer: false, direction: "ltr" };
  },
  methods: {
    openImageClick() {
      ImagePreview({
        images: ["https://carcdn.asred.cn/image/jiamengbiao.jpg"],
        closeable: true,
      });
    },
    jiamengClick() {
      this.$router.push("/phoneJiameng");
    },
    phoneDraClick() {
      this.drawer = false;
      this.$router.push("/phoneHome");
    },
  },
};
</script>
<style lang="less" scoped>
.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  .layout {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    line-height: 50px;
    margin: 10px;

    .layout-logo {
      width: 2.44rem;
      height: 0.81rem;
      .layout-logo-image {
        width: 100%;
        height: 100%;
      }
    }
    .header_icon {
      width: 1rem;
      font-size: 34px;
      color: #000;
      i {
      }
    }
  }
}

.section1 {
  position: relative;
  height: 5.33rem;
  background: linear-gradient(90deg, #fe992f, #ffc97e);
  font-weight: bold;
  padding: 0 0 0 0.39rem;
  .section1_box {
    position: relative;
    z-index: 4;
  }
  .section1_title {
    padding: 1.91rem 0 0 0;

    height: 0.52rem;
    font-size: 0.54rem;
    font-weight: 400;
    color: #fefefe;
  }
  .section1_text {
    margin: 0.56rem 0 0.51rem;
    font-size: 0.27rem;
    font-weight: bold;
    color: #fefefe;
  }
  .section1_btn {
    width: 2.42rem;
    height: 0.45rem;
    line-height: 0.45rem;
    background: #ffd5a1;
    border-radius: 10px;
    padding: 0;
    border: 0;
    font-size: 0.27rem;
    font-weight: bold;
    color: #fea645;
  }
  .section1_img {
    position: absolute;
    bottom: 0;
    right: 279px;
    z-index: 2;
  }
}
.jiameng {
  .jiameng_title {
    margin: 0.63rem 0 0 0.41rem;
    font-weight: bold;
    color: #333333;
    .span1 {
      font-size: 0.4rem;
    }
    .span1 {
      font-size: 0.24rem;
    }
  }
  .jiameng_image {
    margin: 0.55rem 0.4rem;
    height: 7.32rem;
    img {
      width: 100%;
      height: 100%;
    }
  }
} // 弹窗导航
.drawer_ul {
  .drawer_li {
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-bottom: 1px solid #000;
  }
}
</style>