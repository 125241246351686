<template>
  <div class="app">
    <!-- <Header></Header> -->
    <div class="header">
      <div class="layout">
        <div class="layout-logo">
          <img class="layout-logo-image" src="@/assets/1/logo.png" alt="" />
        </div>
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo header_nav"
          mode="horizontal"
          background-color="rgba(0,0,0,0)"
          text-color="#fff"
          active-text-color="#fff"
        >
          <el-menu-item index="1">
            <a href="#section1">网站首页</a>
          </el-menu-item>
          <el-menu-item index="2">
            <a href="#section4">公司简介</a>
          </el-menu-item>
          <el-menu-item index="3" @click="zixunClick()">
            加盟合作
          </el-menu-item>

          <el-menu-item index="4">
            <a href="#section7">联系我们</a>
          </el-menu-item>
          <el-menu-item index="3">
            <a href="#section6">新闻中心</a>
          </el-menu-item>
        </el-menu>

        <el-button class="header_btn">登录</el-button>
      </div>
    </div>

    <!-- 智慧互联，共享便捷 -->

    <section class="section1" id="section1">
      <div class="section1-content">
        <div class="content-text1">智慧互联，共享便捷</div>
        <h3 class="content-text2">柚子24h共享自助洗车</h3>
        <div class="content-text3">
          扫码进入小程序，系统自动识别车辆信息，开闸入场，洗完后，扫码结算，扣费放行。
          无人化运营零人力成本、24小时营业、 车主自助洗车、计时收费模式低价格高
          毛利、多业务符合经营拓展盈利环节
        </div>
        <el-button class="content-btn">前往小程序</el-button>
      </div>
      <div class="left-image">
        <img src="@/assets/1/mainLeft.png" alt="" />
      </div>
      <div class="right-image">
        <img src="@/assets/1/mainRight.png" alt="" />
      </div>
    </section>

    <!-- 加盟流程 -->
    <section class="section2" id="section2">
      <div class="section2_title">
        <div class="section2_title-image">
          <img src="@/assets/2/iconKuang.png" alt="" />
        </div>
        <div class="section2_title-text1">加盟流程</div>
        <div class="section2_title-text2">JOINING PROCESS</div>
      </div>
      <div class="section2_icon">
        <ul class="section2_icon-box section2_icon-ul1">
          <li class="section2_icon-image">
            <img src="@/assets/2/icon1.png" alt="" />
          </li>
          <li class="section2_icon-text">了解咨询</li>
          <li class="section2_icon-title">了解行业、运营机制</li>
        </ul>
        <ul class="section2_icon-box section2_icon-ul2">
          <li class="section2_icon-image">
            <img src="@/assets/2/icon2.png" alt="" />
          </li>
          <li class="section2_icon-text">实地考察</li>
          <li class="section2_icon-title">考察柚子已有的成功案例</li>
        </ul>
        <ul class="section2_icon-box section2_icon-ul3">
          <li class="section2_icon-image">
            <img src="@/assets/2/icon3.png" alt="" />
          </li>
          <li class="section2_icon-text">店面选址</li>
          <li class="section2_icon-title">寻找、挑选合适的地址作为店面</li>
        </ul>
        <ul class="section2_icon-box section2_icon-ul4">
          <li class="section2_icon-image">
            <img src="@/assets/2/icon4.png" alt="" />
          </li>
          <li class="section2_icon-text">签订合同</li>
          <li class="section2_icon-title">签订加盟合同</li>
        </ul>
        <ul class="section2_icon-box section2_icon-ul5">
          <li class="section2_icon-image">
            <img src="@/assets/2/icon5.png" alt="" />
          </li>
          <li class="section2_icon-text">开始营业</li>
          <li class="section2_icon-title">主业副业两不耽误</li>
        </ul>
        <ul class="section2_icon-box section2_icon-ul6">
          <li class="section2_icon-image">
            <img src="@/assets/2/icon6.png" alt="" />
          </li>
          <li class="section2_icon-text">后续服务</li>
          <li class="section2_icon-title">终身软件技术支持与维护</li>
        </ul>
        <div class="section2_icon-bgimage">
          <img src="@/assets/2/iconBg.png" alt="" />
        </div>
      </div>
      <div class="section2_btn">
        <el-button class="section2_btn1" @click="zixunClick()">
          <a>查看详情</a>
        </el-button>
        <el-button class="section2_btn2">
          <a href="#section7"> 立即咨询</a>
        </el-button>
      </div>
    </section>

    <!--环境展示  -->
    <section class="section3">
      <div class="section_title">
        <div class="section_title-image">
          <img src="@/assets/3/iconKuang.png" alt="" />
        </div>
        <div class="section_title-text1">环境展示</div>
        <div class="section_title-text2">ENVIRONMENT SHOW</div>
      </div>

      <div class="section3_xian">
        <div class="section3_xian-span"></div>
      </div>
      <div class="section_list">
        <div class="section_list-box">
          <img src="https://carcdn.asred.cn/image/dianImage.jpg" alt="" />
        </div>
        <div class="section_list-box">
          <img src="https://carcdn.asred.cn/image/dianImage.jpg" alt="" />
        </div>
        <div class="section_list-box">
          <img src="https://carcdn.asred.cn/image/dianImage.jpg" alt="" />
        </div>
        <div class="section_list-box">
          <img src="https://carcdn.asred.cn/image/dianImage.jpg" alt="" />
        </div>
        <div class="section_list-box">
          <img src="https://carcdn.asred.cn/image/dianImage.jpg" alt="" />
        </div>
        <div class="section_list-box">
          <img src="https://carcdn.asred.cn/image/dianImage.jpg" alt="" />
        </div>
        <div class="section_list-box">
          <img src="https://carcdn.asred.cn/image/dianImage.jpg" alt="" />
        </div>
        <div class="section_list-box">
          <img src="https://carcdn.asred.cn/image/dianImage.jpg" alt="" />
        </div>
        <div class="section_list-box">
          <img src="https://carcdn.asred.cn/image/dianImage.jpg" alt="" />
        </div>
      </div>
      <div class="section_list-btn">
        <div class="div">查看更多》</div>
      </div>
      <div class="section_bg">
        <img src="@/assets/3/bg.png" alt="" />
      </div>
    </section>

    <!-- 公司介绍 -->
    <section class="section4" id="section4">
      <div class="section2_title">
        <div class="section2_title-image">
          <img src="@/assets/4/iconKuang.png" alt="" />
        </div>
        <div class="section2_title-text1">公司介绍</div>
        <div class="section2_title-text2">Company introduction</div>
      </div>
      <div class="section4_text">
        <p>邢台合诚网络科技有限公司，总部在河北邢台</p>
        <p>
          致力于开创共享自助洗车新模式，团队有着多年的互联网行业项目及传统汽车美容经验
        </p>
        <p>现在公司打造柚子线上共享自助洗车平台，通过日常洗车的高频的场景</p>
        <p>打造共享自助洗车新模式，现诚邀全国加盟商</p>
      </div>
      <div class="section4_image">
        <img src="http://carcdn.asred.cn/image/index_gif.gif" alt="" />
      </div>
    </section>
    <!-- 品牌优势 -->
    <section class="section5">
      <div class="section5_title">品牌优势</div>
      <ul class="section5_ul">
        <li class="section5_li">
          <span>采用全云端服务，</span>
          <span>依托腾讯云技术，</span>
          <span>提供安全可靠的服务支持，</span>
          <span>无需再配电脑。</span>
        </li>
        <li class="section5_li">
          <span> 自有技术团队开发，</span>
          <span> 非外包技术可比，</span>
          <span> 更新升级迅速，</span>
          <span> 拥有完全自主知识产权。</span>
        </li>
        <li class="section5_li">
          <span>自动通断电、水、气，</span>
          <span>大幅降低消耗性经营成本， </span>
          <span>减少浪费，</span>
          <span>提高利润率。</span>
        </li>
        <li class="section5_li">
          <span> 店主小程序端管理，</span>
          <span> 操作便利，</span>
          <span> 经营数据一目了然，</span>
          <span> 真正做到无人值守坐享其成。</span>
        </li>
      </ul>
    </section>

    <!-- 新闻中心 -->
    <section class="section6" id="section6">
      <div class="section2_title">
        <div class="section2_title-image">
          <img src="@/assets/4/iconKuang.png" alt="" />
        </div>
        <div class="section2_title-text1">新闻中心</div>
        <div class="section2_title-text2">News Center</div>
      </div>

      <div class="section_flex">
        <div class="section_flex-box">
          <div class="flex1">01</div>
          <div class="flex2">
            <h1 class="flex-h1">市场大、前景广</h1>
            <div class="flex-time">2022-04-06 15:38:39</div>
            <p class="flex-p">
              国内私家车突破3亿辆，平均每2个家庭就有1辆
              车车主普遍每月洗车频率再2~3次
            </p>
          </div>
        </div>
        <div class="section_flex-box">
          <div class="flex1">02</div>
          <div class="flex2">
            <h1 class="flex-h1">创新之处、核心优势分析、 门...</h1>
            <div class="flex-time">2022-04-06 15:38:39</div>
            <p class="flex-p">
              无需任何操作，系统自动识别车辆信息，开闸入
              场，洗完后，自动识别信息，扣费放行。
            </p>
          </div>
        </div>
      </div>

      <div class="section6-btn">
        <el-button class="btn">查看更多文章</el-button>
      </div>
    </section>
    <!-- 联系我们 -->
    <section class="section7" id="section7">
      <h1 class="h1">联系我们</h1>
      <div class="h3">值得信赖的共享自助洗车平台</div>
      <div class="input_box">
        <div class="input-name">
          <div class="name">姓名：</div>
          <el-input class="input" v-model="name"></el-input>
        </div>
        <div class="input-phone">
          <div class="name">联系方式：</div>
          <el-input class="input" v-model="phone"></el-input>
        </div>
      </div>
      <div class="input_liuyan">
        <el-input
          type="textarea"
          :rows="2"
          placeholder="请输入内容"
          v-model="msg"
        >
        </el-input>

        <el-button class="input_btn" @click="getStore">立即提交</el-button>
      </div>
      <div class="sec7_image"><img src="@/assets/6/iconBg.png" alt="" /></div>
    </section>
    <el-backtop>
      <i class="el-icon-caret-top"></i>
    </el-backtop>
  </div>
</template>
<script>
import Header from "@/components/header/header.vue";
export default {
  components: { Header },
  data() {
    return {
      status: false,
      active: 0, // 当前激活的导航索引
      scrollTop: "", //滚动距离
      phone: "",
      name: "",
      msg: "",
      activeIndex: "1",
    };
  },
  // mounted() {
  //   // 监听滚动事件
  //   // document.getElementById("blockmain").addEventListener("scroll", this.handleScroll)
  //   // window.addEventListener('scroll', this.handleScroll)
  //   this.$nextTick(function () {
  //     window.addEventListener("scroll", this.handleScroll);
  //   });
  // },

  methods: {
    // 接口
    getStore() {
      let that = this;
      if (that.name == "") {
        this.$message.error("请输入姓名");
        return;
      }
      if (!that.checkPhone(that.phone)) {
        that.$message.error("手机号格式不正确");
        return;
      }
      if (that.msg == "") {
        that.$message.error("请输入留言信息");
        return;
      }

      let data = {
        name: that.name,
        phone: that.phone,
        msg: that.msg,
      };
      const loading = this.$loading({
        lock: true,
        text: "提交中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      that
        .$axios({
          method: "post", //指定请求方式
          url: "index/jmsq", //请求接口（相对接口，后面会介绍到）
          data: data,
        })
        .then(function (res) {
          setTimeout(() => {
            if (res.data.code == 1) {
              that.$message({
                message: "留言成功，请等待客服联系",
                type: "success",
              });
              that.msg = "";
            } else {
              that.$message.error("留言失败");
            }
            loading.close();
          }, 2000);
        })
        .catch(function (err) {
          console.log(err);
          setTimeout(() => {
            loading.close();
          }, 2000);
        });
    },
    checkPhone(phone) {
      if (!/^1[3456789]\d{9}$/.test(phone)) {
        return false;
      } else {
        return true;
      }
    },
    zixunClick() {
      this.$router.push("/jiameng");
    },
    a() {
      // handleSelect(key, keyPath) {
      //   console.log(key, keyPath);
      // },
      // //跳转到指定元素
      // scrollTo() {
      //   // 定义一次跳 50 个像素，数字越大跳得越快，但是会有掉帧得感觉
      //   const STEP = 50;
      //   let scrollTop =
      //     document.documentElement.scrollTop || document.body.scrollTop; //获取滚动距离
      //   var targetOffsetTop = "";
      //   // if (val == 1) {
      //   //   targetOffsetTop = document.querySelector(".h5-os").offsetTop - 60; //获取指定元素视口距离
      //   // }
      //   // if (val == 2) {
      //   //   targetOffsetTop = document.querySelector(".h5-oslight").offsetTop - 60; //获取指定元素视口距离
      //   // }
      //   // if (val == 3) {
      //   //   targetOffsetTop =
      //   //     document.querySelector(".contactService").offsetTop - 60; //获取指定元素视口距离
      //   // }
      //   if (scrollTop < targetOffsetTop) {
      //     // 往下滑
      //     smoothDown();
      //     console.log(targetOffsetTop, "视口距离");
      //   } else {
      //     console.log(targetOffsetTop, "targetOffsetTop");
      //     smoothUp();
      //   }
      //   // 定义下滑函数
      //   function smoothDown() {
      //     console.log(scrollTop, "scrollTop");
      //     // 如果当前 scrollTop 小于 targetOffsetTop 说明视口还没滑到指定位置
      //     if (scrollTop < targetOffsetTop) {
      //       // 如果和目标相差距离大于等于 STEP 就跳加上 STEP
      //       // 否则直接跳到目标点，目标是为了防止跳过了。
      //       if (targetOffsetTop - scrollTop >= STEP) {
      //         scrollTop += STEP;
      //       } else {
      //         scrollTop = targetOffsetTop;
      //       }
      //       document.body.scrollTop = scrollTop;
      //       document.documentElement.scrollTop = scrollTop;
      //       // 屏幕在绘制下一帧时会回调传给 requestAnimationFrame 的函数
      //       // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
      //       requestAnimationFrame(smoothDown);
      //     }
      //   }
      //   // 定义上滑函数
      //   function smoothUp() {
      //     // 如果当前 scrollTop 大于 targetOffsetTop 说明视口已经滑过指定位置需要上滑
      //     if (scrollTop > targetOffsetTop) {
      //       // 如果和目标相差距离大于等于 STEP 就跳减去 STEP
      //       // 否则直接跳到目标点，目标是为了防止跳过了。
      //       if (scrollTop - targetOffsetTop >= STEP) {
      //         scrollTop -= STEP;
      //       } else {
      //         scrollTop = targetOffsetTop;
      //       }
      //       document.body.scrollTop = scrollTop;
      //       document.documentElement.scrollTop = scrollTop;
      //       // 屏幕在绘制下一帧时会回调传给 requestAnimationFrame 的函数
      //       // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
      //       requestAnimationFrame(smoothUp);
      //     }
      //   }
      // },
      // getColor() {
      //   // //当滚动距离超过导航栏高度时改变背景色
      //   // if (this.scrollTop > 45) {
      //   //   return {
      //   //     background: "#FFA056",
      //   //   };
      //   // } else {
      //   //   return false;
      //   // }
      // },
      // change(status) {
      //   console.log(status);
      //   this.status = status;
      //   this.$Message.info(`当前状态：${status}`);
      // },
      // handleScroll() {
      //   const scrollTop =
      //     document.documentElement.scrollTop || document.body.scrollTop; //获取滚动距离
      //   this.scrollTop = scrollTop; //data里return了一个全局的scrollTop
      //   // console.log(scrollTop);
      //   //这边距离我是通过打印scrollTop大致自己粗略的计算了下，用来判断滚轮滚动相应的距离改变导航栏对应菜单样式
      //   if (scrollTop > 1115) {
      //     this.active = 1;
      //   }
      //   if (scrollTop > 1850) {
      //     this.active = 2;
      //   }
      //   if (scrollTop < 1000) {
      //     this.active = 0;
      //   }
      // },
    },
  },
};
</script>
<style scoped lang="less">
/deep/.el-menu.el-menu--horizontal {
  border: none;
}
/deep/.el-menu-item {
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ffffff;
}
/deep/.el-menu--horizontal > .el-menu-item.is-active {
  border: 0;
  color: #fff;
}
/deep/ .el-menu--horizontal .el-menu-item:not(.is-disabled):focus,
/deep/ .el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
  color: #fff;
}
/deep/ .el-input__inner {
  height: 68px;
  border: none;
  font-size: 24px;
}
/deep/ .el-textarea {
  font-size: 24px;
}
</style>
<style lang="less" scoped>
.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 1860px;
  height: 160px;
  z-index: 99;
  .layout {
    display: flex;
    padding: 35px 0;
    align-items: center;
    justify-content: center;
    .layout-logo {
      margin-right: 90px;
      width: 272px;
      height: 91px;
      .layout-logo-image {
        width: 100%;
        height: 100%;
      }
    }
    .header_nav {
      margin-right: 81px;
      font-size: 24px;
      font-weight: bold;
      span {
        color: #ffffff;
      }
    }

    .header_btn {
      width: 152px;
      height: 57px;
      background: linear-gradient(0deg, #ff4e00, #ffa054);
      border: 0;
      font-size: 24px;
      font-weight: bold;
      color: #ffffff;
      border-radius: 50px;
    }
  }
  a {
    font-size: 24px;
  }
}
.section1 {
  position: relative;
  height: 1195px;
  padding: 0 360px;
  .section1-content {
    padding: 321px 0;
    .content-text1 {
      font-size: 25px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffffff;
    }
    .content-text2 {
      font-size: 65px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffffff;
      line-height: 90px;
    }
    .content-text3 {
      width: 499px;
      font-size: 21px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffffff;
      line-height: 50px;
      margin-bottom: 65px;
    }
    .content-btn {
      font-size: 30px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ff5509;
      width: 201px;
      background: #ffffff;
      box-shadow: 0px 7px 27px 0px rgba(17, 17, 17, 0.17);
      border-radius: 50px;
    }
  }
  .left-image {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 1368px;
    height: 1195px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .right-image {
    position: absolute;
    bottom: 0;
    right: 0;

    z-index: -1;
    width: 1080px;
    height: 815px;
  }
}
// 加盟流程
.section2 {
  padding-top: 90px;
  .section2_title {
    position: relative;
    margin: 0 auto 0;
    text-align: center;
    width: 257px;
    height: 89px;
    .section2_title-image {
      position: absolute;
      width: 100%;
      height: 100%;
      width: 257px;
      height: 89px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .section2_title-text1 {
      font-size: 40px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #474749;
    }
    .section2_title-text2 {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #525355;
    }
  }
  .section2_icon {
    padding: 0 360px;
    position: relative;
    display: flex;
    justify-content: space-between;
    // height: 520px;
    .section2_icon-box {
      text-align: center;
      .section2_icon-image {
        margin: 14px auto 9px;
        width: 86px;
        text-align: center;
        height: 86px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .section2_icon-text {
        margin: 14px auto 9px;
        font-size: 15px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #ff5509;
      }
      .section2_icon-title {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #515152;
      }
    }
    .section2_icon-ul1,
    .section2_icon-ul3,
    .section2_icon-ul6 {
      margin-top: 110px;
    }
    .section2_icon-ul2,
    .section2_icon-ul4 {
      margin-top: 216px;
    }
    .section2_icon-ul5 {
      margin-top: 161px;
    }
    .section2_icon-bgimage {
      position: absolute;
      left: 50%;
      top: 43%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 152px;
      z-index: -1;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .section2_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 112px;
    padding-bottom: 99px;

    .section2_btn1,
    .section2_btn2 {
      margin: 0;
      padding: 0;
      width: 120px;
      height: 50px;
      border: 2px solid #e6e6e6;
      font-size: 18px;
      font-weight: 400;
      color: #333333;
    }
    .section2_btn1 {
      border-right: 0;
    }
  }
}
// 环境展示
.section3 {
  position: relative;
  overflow: hidden;
  padding: 90px 360px;
  .section_bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -3;
    width: 1920px;
    height: 1139px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .section_title {
    position: relative;
    z-index: 5;
    margin: 0 auto 0;
    text-align: center;
    width: 257px;
    height: 89px;
    .section_title-image {
      position: absolute;
      width: 100%;
      height: 100%;
      width: 257px;
      height: 89px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .section_title-text1 {
      font-size: 40px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffffff;
    }
    .section_title-text2 {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffffff;
    }
  }
  .section3_xian {
    position: relative;
    margin: 61px 0 80px;
    .section3_xian-span {
      width: 50px;
      height: 10px;
      background: #f9910a;
      margin: auto;
    }
    &::before {
      content: "";
      width: 120%;

      height: 3px;
      display: block;
      position: absolute;
      z-index: -1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #fff;
    }
  }
  .section_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .section_list-box {
      margin: 0 0px 22px 0;
      border: 1px solid #000;
      width: 360px;
      height: 177px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .section_list-btn {
    position: relative;
    margin: auto;
    width: 349px;
    height: 69px;
    border-radius: 17px;
    background: rgba(251, 167, 8, 0.3);
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    .div {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 335px;
      height: 54px;
      line-height: 54px;
      text-align: center;
      background: linear-gradient(0deg, #f67b0d, #fcaf07);
      border-radius: 12px;
    }
  }
}
// 公司介绍
.section4 {
  padding: 105px 360px 0;

  background: #fff;

  .section2_title {
    position: relative;
    margin: 0 auto 0;
    text-align: center;
    width: 257px;
    height: 89px;
    .section2_title-image {
      position: absolute;
      width: 100%;
      height: 100%;
      width: 257px;
      height: 89px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .section2_title-text1 {
      font-size: 40px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #474749;
    }
    .section2_title-text2 {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #525355;
    }
  }
  .section4_text {
    margin: 85px 0 0;
    p {
      text-align: center;
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #474749;
      line-height: 40px;
    }
  }
  .section4_image {
    width: 588px;
    height: 588px;
    margin: auto;

    img {
      width: 100%;
      height: 100%;
    }
  }
}
// 品牌优势
.section5 {
  position: relative;
  padding: 0 360px;
  height: 384px;
  background: rgba(251, 167, 8, 0.3);
  border-radius: 28px;
  &::before {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    content: "";
    width: 99.4%;
    // margin: 0 5px;
    // padding: 0 360px;
    height: 374px;
    background: linear-gradient(0deg, #f67b0d, #fcaf07);
    border-radius: 28px;
  }
  .section5_title {
    text-align: center;
    margin: auto;
    padding: 58px 0;
    font-size: 34px;
    font-weight: 400;
    color: #ffffff;
  }
  .section5_ul {
    display: flex;
    align-items: center;
    justify-content: space-around;

    .section5_li {
      width: 185px;
      line-height: 45px;
      position: relative;
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      line-height: 45px;
      span {
        display: block;
        text-align: center;
      }
    }

    .section5_li:first-of-type,
    .section5_li:nth-child(2),
    .section5_li:nth-child(3) {
      &::after {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        right: -25%;
        transform: translate(-50%, -50%);
        width: 1px;
        height: 158px;
        background: #fff;
      }
    }
  }
}
// 新闻中心
.section6 {
  padding: 0 360px;
  .section2_title {
    position: relative;
    margin: 132px auto 0;
    text-align: center;
    width: 257px;
    height: 89px;
    .section2_title-image {
      position: absolute;
      width: 100%;
      height: 100%;
      width: 257px;
      height: 89px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .section2_title-text1 {
      font-size: 40px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #474749;
    }
    .section2_title-text2 {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #525355;
    }
  }
  .section_flex {
    display: flex;
    margin: 65px 0 0;

    .section_flex-box {
      width: 590px;
      display: flex;
      background: #f5f5f5;
      padding: 53px 24px 63px 18px;
      margin: 0 20px 0 0;
      .flex1 {
        flex: 1;
        margin-right: 8px;
        font-size: 36px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #fafafa;
        line-height: 40px;

        background: linear-gradient(0deg, #f7810c 0%, #fba808 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .flex2 {
      }
      .flex-h1 {
        font-size: 36px;
        color: #333333;
        font-weight: Bold;
        width: 100%;
        display: block;
        overflow: hidden;
        white-space: nowrap;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
      }
      .flex-time {
        margin: 15px 0;
        font-size: 16px;
        color: #999999;
        font-weight: Bold;
      }
      .flex-p {
        font-size: 24px;
        color: #333333;
        font-weight: Bold;
      }
    }
  }
  .section6-btn {
    margin: 54px auto 71px;
    text-align: center;
    .btn {
      width: 240px;
      height: 50px;
      border: 2px solid #e6e6e6;
    }
  }
}
// 联系我们
.section7 {
  position: relative;
  color: #fff;
  padding: 0 360px;
  .h1 {
    padding-top: 63px;
    margin: 0 0 40px 0;
    font-size: 65px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    text-shadow: 0px 12px 11px rgba(0, 0, 0, 0.45);
  }
  .h3 {
    margin: 0 0 0 0;
    font-size: 29px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    text-shadow: 0px 7px 5px rgba(0, 0, 0, 0.45);
  }

  .input_box {
    margin: 0 0 40px 0;
    display: flex;
    .input-name,
    .input-phone {
      display: flex;
      align-items: center;
      width: 100%;
      background: #fff;
      .name {
        flex: none;
        padding: 23px 0 23px 31px;
        font-size: 22px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #494949;
      }
    }
    .input-name {
      margin-right: 14px;
    }
  }
  .input_liuyan {
    text-align: right;
    font-size: 24px;
    .input_btn {
      width: 162px;
      height: 57px;
      margin: 27px 0 0 0;
      border: 0;
      background: linear-gradient(0deg, #f7810c, #fba808);
      text-align: right;
      font-size: 22px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      text-align: center;
    }
  }
  .sec7_image {
    width: 100%;
    height: 578px;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

.section1,
.section3,
.section4,
.section5,
.section6,
.section7 {
  width: 1140px;
}
.firset-herad {
  height: 100%;
}
</style>>



