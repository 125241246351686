import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)


const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err);
};

import pcHome from '../views/pc/home/home.vue'
import jiameng from '../views/pc/jiameng/jiameng.vue'
import phoneHome from '../views/phone/home/home.vue'
import phoneJiameng from '../views/phone/jiameng/jiameng.vue'


const routes = [
  {
    path: '/',
    redirect: '/pcHome'
  },
  {
    path: '/pcHome',
    component: pcHome
  },
  {
    path: '/phoneHome',
    component: phoneHome
  },
  {
    path: '/jiameng',
    component: jiameng
  },
  {
    path: '/phoneJiameng',
    component: phoneJiameng
  },

  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = new VueRouter({
  routes
})

export default router
