<template>
  <div class="home">
    <div class="header">
      <div class="layout">
        <div class="layout-logo">
          <img class="layout-logo-image" src="@/assets/1/logo.png" alt="" />
        </div>
        <div class="header_icon" @click="drawer = true">
          <i class="el-icon-s-unfold"></i>
        </div>
      </div>
    </div>

    <!-- 智慧互联，共享便捷 -->

    <section class="section1" id="section1">
      <div class="section1-content">
        <h3 class="content-text2">柚子24h共享自助洗车</h3>
        <div class="content-text3">
          扫码进入小程序，系统自动识别车辆信息，开闸入场，洗完后，扫码结算，扣费放行。
          无人化运营零人力成本、24小时营业、 车主自助洗车、计时收费模式低价格高
          毛利、多业务符合经营拓展盈利环节
        </div>
        <el-button class="content-btn">前往小程序</el-button>
      </div>
      <div class="phone_top">
        <img src="@/assets/phone/phone_top.png" alt="" />
      </div>
      <!-- <div class="left-image">
        <img src="@/assets/1/mainLeft.png" alt="" />
      </div>
      <div class="right-image">
        <img src="@/assets/1/mainRight.png" alt="" />
      </div> -->
    </section>

    <!-- 加盟流程 -->
    <section class="section2" id="section2">
      <div class="section2_title">
        <div class="section2_title-image">
          <img src="@/assets/2/iconKuang.png" alt="" />
        </div>
        <div class="section2_title-text1">加盟流程</div>
        <div class="section2_title-text2">JOINING PROCESS</div>
      </div>
      <div class="section2_icon">
        <img src="@/assets/phone/phone_liucheng.png" alt="" />
      </div>
    </section>

    <!--环境展示  -->
    <section class="section3" id="section3">
      <div class="section_title">
        <div class="section_title-image">
          <img src="@/assets/3/iconKuang.png" alt="" />
        </div>
        <div class="section_title-text1">环境展示</div>
        <div class="section_title-text2">ENVIRONMENT SHOW</div>
      </div>

      <div class="section_list">
        <div class="section_list-box">
          <img src="https://carcdn.asred.cn/image/dianImage.jpg" alt="" />
        </div>
        <div class="section_list-box">
          <img src="https://carcdn.asred.cn/image/dianImage.jpg" alt="" />
        </div>
      </div>
      <div class="section_list-btn">
        <div class="div">查看更多》</div>
      </div>
    </section>

    <!-- 公司介绍 -->
    <section class="section4" id="section4">
      <div class="section2_title">
        <div class="section2_title-image">
          <img src="@/assets/4/iconKuang.png" alt="" />
        </div>
        <div class="section2_title-text1">公司介绍</div>
        <div class="section2_title-text2">Company introduction</div>
      </div>
      <div class="section4_text">
        <p>邢台合诚网络科技有限公司，总部在河北邢台</p>
        <p>
          致力于开创共享自助洗车新模式，团队有着多年的互联网行业项目及传统汽车美容经验
        </p>
        <p>现在公司打造柚子线上共享自助洗车平台，通过日常洗车的高频的场景</p>
        <p>打造共享自助洗车新模式，现诚邀全国加盟商</p>
      </div>
      <div class="section4_image">
        <img src="http://carcdn.asred.cn/image/index_gif.gif" alt="" />
      </div>
    </section>

    <!-- 品牌优势 -->
    <section class="section5" id="section5">
      <div class="section2_title">
        <div class="section2_title-image">
          <img src="@/assets/4/iconKuang.png" alt="" />
        </div>
        <div class="section2_title-text1">品牌优势</div>
        <div class="section2_title-text2">Brand advantage</div>
      </div>
      <ul class="section5_ul">
        <li class="section5_li">
          采用全云端服务，依托腾讯云技术，提供安全可靠的服务支持，无需再配电脑。
        </li>
        <li class="section5_li">
          自有技术团队开发（非外包技术可比)，更新升级迅速，
          拥有完全自主知识产权。
        </li>
        <li class="section5_li">
          自动通断电、水、气，大幅降低消耗性经营成本， 减少浪费，提高利润率。
        </li>
        <li class="section5_li">
          店主小程序端管理，操作便利，经营数据一目了然，
          真正做到无人值守坐享其成。
        </li>
      </ul>
    </section>
    <!-- 新闻中心 -->
    <section class="section6" id="section6">
      <div class="section2_title">
        <div class="section2_title-image">
          <img src="@/assets/4/iconKuang.png" alt="" />
        </div>
        <div class="section2_title-text1">新闻中心</div>
        <div class="section2_title-text2">News Center</div>
      </div>
      <div class="section_flex">
        <div class="section_flex-box">
          <div class="flex1">01</div>
          <div class="flex2">
            <h1 class="flex-h1">市场大、前景广</h1>
            <div class="flex-time">2022-04-06 15:38:39</div>
            <p class="flex-p">
              国内私家车突破3亿辆，平均每2个家庭就有1辆
              车车主普遍每月洗车频率再2~3次
            </p>
          </div>
        </div>
        <div class="section_flex-box">
          <div class="flex1">02</div>
          <div class="flex2">
            <h1 class="flex-h1">创新之处、核心优势分析、 门...</h1>
            <div class="flex-time">2022-04-06 15:38:39</div>
            <p class="flex-p">
              无需任何操作，系统自动识别车辆信息，开闸入
              场，洗完后，自动识别信息，扣费放行。
            </p>
          </div>
        </div>
      </div>
    </section>

    <!-- 联系我们 -->
    <section class="section7" id="section7">
      <h1 class="h1">联系我们</h1>
      <div class="h3">值得信赖的共享自助洗车平台</div>
      <div class="input-name">
        <div class="name" v-if="name == ''">姓名：</div>
        <el-input class="input" v-model="name"></el-input>
      </div>
      <div class="input-phone">
        <div class="name" v-if="phone == ''">联系方式：</div>
        <el-input class="input" v-model="phone"></el-input>
      </div>
      <div class="input_liuyan">
        <div class="name" v-if="msg == ''">请输入您的留言内容</div>
        <el-input type="textarea" :rows="2" v-model="msg"> </el-input>
      </div>
      <el-button class="input_btn" @click="getStore">立即提交</el-button>
    </section>

    <el-backtop>
      <i class="el-icon-caret-top"></i>
    </el-backtop>

    <el-drawer :visible.sync="drawer" :direction="direction" :size="'100%'">
      <ul class="drawer_ul">
        <li class="drawer_li">
          <a href="#section1" @click="drawer = false">网站首页</a>
        </li>
        <li class="drawer_li">
          <a href="#section4" @click="drawer = false">公司简介</a>
        </li>
        <li class="drawer_li" @click="jiamengClick"><a>加盟合作</a></li>
        <li class="drawer_li">
          <a href="#section7" @click="drawer = false">联系我们</a>
        </li>
        <li class="drawer_li">
          <a href="#section6" @click="drawer = false">新闻中心</a>
        </li>
      </ul>
    </el-drawer>
  </div>
</template>
<script>
import "@/assets/flexible";

export default {
  components: {},
  data() {
    return {
      status: false,
      active: 0, // 当前激活的导航索引
      scrollTop: "", //滚动距离
      phone: "",
      name: "",
      msg: "",
      activeIndex: "1",
      drawer: false,
      direction: "ltr",
    };
  },
  mounted() {},
  methods: {
    // 接口
    getStore() {
      let that = this;
      if (that.name == "") {
        this.$message.error("请输入姓名");
        return;
      }
      if (!that.checkPhone(that.phone)) {
        that.$message.error("手机号格式不正确");
        return;
      }
      if (that.msg == "") {
        that.$message.error("请输入留言信息");
        return;
      }

      let data = {
        name: that.name,
        phone: that.phone,
        msg: that.msg,
      };
      that
        .$axios({
          method: "post", //指定请求方式
          url: "api/index/jmsq", //请求接口（相对接口，后面会介绍到）
          data: data,
        })
        .then(function (res) {
          console.log(res);
          if (res.data.code == 1) {
            that.$message({
              message: "留言成功，请等待客服联系",
              type: "success",
            });
            that.msg = "";
          } else {
            that.$message.error("留言失败");
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    checkPhone(phone) {
      if (!/^1[3456789]\d{9}$/.test(phone)) {
        return false;
      } else {
        return true;
      }
    },
    jiamengClick() {
      this.$router.push("/phoneJiameng");
    },
    phoneDraClick() {
      this.drawer = false;
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;

  .layout {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    line-height: 50px;
    margin: 10px;

    .layout-logo {
      width: 2.44rem;
      height: 0.81rem;
      .layout-logo-image {
        width: 100%;
        height: 100%;
      }
    }
    .header_icon {
      width: 1rem;
      font-size: 34px;
      color: #000;
      i {
      }
    }
  }
}

// 弹窗导航
.drawer_ul {
  .drawer_li {
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-bottom: 1px solid #000;
  }
}
// 柚子24h共享自助洗车
.section1 {
  position: relative;
  height: 7.81rem;
  .phone_top {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 7.81rem;
    z-index: -1;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .section1-content {
    margin: 0 0 0 0.36rem;
    padding: 2.19rem 0 0 0;
    font-size: 12px;
    font-weight: bold;
    color: #ffffff;
    .content-text2 {
      font-size: 0.59rem;
      font-weight: bold;
      color: #ffffff;
    }
    .content-text3 {
      padding: 0.4rem 0;
      width: 4.11rem;
    }
    .content-btn {
      width: 2.68rem;
      height: 0.68rem;
      line-height: 0.68rem;

      background: #ffffff;
      box-shadow: 0px 4px 13px 0px rgba(17, 17, 17, 0.17);
      color: #ff5509;
      margin: 0;
      padding: 0;
      font-size: 0.4rem;
      font-weight: bold;
      color: #ff5509;
      border-radius: 50px;
    }
  }
}

// 加盟流程
.section2 {
  position: relative;
  margin-top: 1.97rem;
  .section2_title {
    position: relative;
    margin: 0 auto 0;
    text-align: center;
    width: 3.43rem;
    height: 1.19rem;
    .section2_title-image {
      position: absolute;
      width: 100%;
      height: 100%;
      width: 3.43rem;
      height: 1.19rem;
      img {
        width: 100%;
        height: 100%;
      }
    }
    font-weight: bold;
    color: #525355;
    .section2_title-text1 {
      font-size: 0.53rem;
    }
    .section2_title-text2 {
      font-size: 0.21rem;
    }
  }
  .section2_icon {
    margin: 0.28rem 0 0 0;
    width: 100%;
    height: 6.55rem;
    z-index: -1;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .section2_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 112px;
    padding-bottom: 99px;

    .section2_btn1,
    .section2_btn2 {
      margin: 0;
      padding: 0;
      width: 120px;
      height: 50px;
      border: 2px solid #e6e6e6;
      font-size: 18px;
      font-weight: 400;
      color: #333333;
    }
    .section2_btn1 {
      border-right: 0;
    }
  }
}
// 环境展示
.section3 {
  position: relative;
  overflow: hidden;
  padding-top: 1.45rem;
  .section_title {
    position: relative;
    margin: 0 auto 0;
    text-align: center;
    width: 3.43rem;
    height: 1.19rem;
    .section_title-image {
      position: absolute;
      width: 100%;
      height: 100%;
      width: 3.43rem;
      height: 1.19rem;
      img {
        width: 100%;
        height: 100%;
      }
    }
    font-weight: bold;
    color: #000;
    .section_title-text1 {
      font-size: 0.53rem;
    }
    .section_title-text2 {
      font-size: 0.21rem;
    }
  }
  .section_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0.8rem 0.37rem 0.75rem;
    .section_list-box {
      border: 1px solid #000;
      width: 4.51rem;
      height: 2.06rem;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .section_list-btn {
    position: relative;
    margin: auto;
    width: 3.93rem;
    height: 0.78rem;
    border-radius: 17px;
    background: rgba(251, 167, 8, 0.3);
    font-size: 0.27rem;
    font-weight: 400;
    color: #ffffff;
    .div {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 3.53rem;
      height: 0.58rem;
      line-height: 0.58rem;
      text-align: center;
      background: linear-gradient(0deg, #f67b0d, #fcaf07);
      border-radius: 12px;
    }
  }
}
// 公司介绍
.section4 {
  position: relative;
  padding-top: 1.77rem;
  background: #fff;
  .section2_title {
    position: relative;
    margin: 0 auto 0;
    text-align: center;
    width: 3.43rem;
    height: 1.19rem;
    .section2_title-image {
      position: absolute;
      width: 100%;
      height: 100%;
      width: 3.43rem;
      height: 1.19rem;
      img {
        width: 100%;
        height: 100%;
      }
    }
    font-weight: bold;
    color: #525355;
    .section2_title-text1 {
      font-size: 0.53rem;
    }
    .section2_title-text2 {
      font-size: 0.21rem;
    }
  }
  .section4_text {
    margin: 0.88rem 0.17rem 0;
    p {
      text-align: center;
      font-size: 0.24rem;
      font-weight: 400;
      color: #474749;
    }
  }
  .section4_image {
    height: 9rem;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
// 品牌优势

.section5 {
  .section2_title {
    position: relative;
    margin: 0 auto 0;
    text-align: center;
    width: 3.43rem;
    height: 1.19rem;
    .section2_title-image {
      position: absolute;
      width: 100%;
      height: 100%;
      width: 3.43rem;
      height: 1.19rem;
      img {
        width: 100%;
        height: 100%;
      }
    }
    font-weight: bold;
    color: #525355;
    .section2_title-text1 {
      font-size: 0.53rem;
    }
    .section2_title-text2 {
      font-size: 0.21rem;
    }
  }
  .section5_ul {
    margin: 0.77rem auto 0;

    .section5_li {
      margin: auto;
      width: 6.55rem;
      font-size: 0.24rem;
      font-weight: 300;
      color: #474749;
      line-height: 0.67rem;
      text-align: center;
    }
  }
}

// 新闻中心
.section6 {
  padding-top: 1rem;
  .section2_title {
    position: relative;
    margin: 0 auto 0;
    text-align: center;
    width: 3.43rem;
    height: 1.19rem;
    .section2_title-image {
      position: absolute;
      width: 100%;
      height: 100%;
      width: 3.43rem;
      height: 1.19rem;
      img {
        width: 100%;
        height: 100%;
      }
    }
    font-weight: bold;
    color: #525355;
    .section2_title-text1 {
      font-size: 0.53rem;
    }
    .section2_title-text2 {
      font-size: 0.21rem;
    }
  }
  .section_flex {
    font-weight: bold;
    margin: 1rem 0;

    .section_flex-box {
      display: flex;
      margin: 0.48rem 1.07rem 0;
      padding: 0.8rem 0.31rem;

      background: #f5f5f5;
      .flex1 {
        margin-right: 8px;
        font-size: 0.48rem;
        color: #fafafa;
        background: linear-gradient(0deg, #f7810c 0%, #fba808 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      .flex-h1 {
        font-size: 0.48rem;
        color: #333333;
        width: 100%;
        display: block;
        overflow: hidden;
        white-space: nowrap;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
      }
      .flex-time {
        margin: 0.1rem 0;
        font-size: 0.21rem;
        color: #999999;
      }
      .flex-p {
        font-size: 0.32rem;
        color: #333333;
      }
    }
  }
  .section6-btn {
    margin: 54px auto 71px;
    text-align: center;
    .btn {
      width: 240px;
      height: 50px;
      border: 2px solid #e6e6e6;
    }
  }
}
// 联系我们
.section7 {
  position: relative;
  color: #fff;
  font-weight: 400;
  background: #333333;
  text-align: center;
  margin: auto;
  .h1 {
    padding-top: 1.61rem;
    margin: 0 0 0.63rem 0;
    font-size: 0.87rem;
    color: #ffffff;
    line-height: 0.28rem;
    text-shadow: 0px 6px 5px rgba(0, 0, 0, 0.45);
  }
  .h3 {
    margin: 0 0 0 0;
    font-size: 0.39rem;
    color: #ffffff;
    text-shadow: 0px 6px 5px rgba(0, 0, 0, 0.45);
  }

  .input-name,
  .input-phone {
    position: relative;
    margin: 0.35rem 1.13rem 0;
    height: 0.91rem;
    background: #fff;
  }
  .input_liuyan {
    position: relative;
    margin: 0.35rem 1.13rem 0;
    text-align: right;
    font-size: 24px;
  }
  .name {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
    font-size: 0.3rem;
    font-weight: 400;
    color: #494949;
  }
  .input_btn {
    width: 7.73rem;
    padding: 0;
    margin: 0;

    height: 0.76rem;
    background: linear-gradient(0deg, #f7810c, #fba808);
    margin: 0.47rem 0 1.72rem 0;
    border: 0;
    background: linear-gradient(0deg, #f7810c, #fba808);
    text-align: right;
    font-size: 0.3rem;
    font-weight: 400;
    color: #333333;
    text-align: center;
  }
}
.el-drawer {
  width: 100%;
}
// .section1,
// .section3,
// .section4,
// .section5,
// .section6,
// .section7,
// .section5 {
//   width: 1140px;
// }
.firset-herad {
  height: 100%;
}
</style>>



