import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios'
// axios.defaults.baseURL = 'https://car.asred.cn';
axios.defaults.baseURL = 'https://car.asred.cn/api/';

Vue.prototype.$axios = axios;//把axios挂载到vue上
Vue.use(ElementUI);

import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(Vant);

import './assets/Reset.css';    // 使用 CSS
Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
