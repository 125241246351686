<template >
  <!-- vue实例外创建 -->
  <div class="jiamengBox">
    <Header></Header>
    <div class="section1">
      <div class="section1_box">
        <div class="section1_title">柚子自助洗车，产品称心，服务如意。</div>
        <div class="section1_text">
          公司提供建店选址建议、装修方案、装修指导、耗材配送
          开业前远程指导安装培训、终身系统技术支持及维护
        </div>
        <el-button class="section1_btn"> 前往小程序 </el-button>
      </div>
      <div class="section1_img">
        <img src="@/assets/jiameng1_bg.png" alt="" />
      </div>
    </div>
    <div class="jiameng">
      <div class="jiameng_title">
        <span>柚子共享洗车加盟费用-</span>
        <span>开业限时优惠价</span>
      </div>

      <div class="jiameng_image">
        <img src="@/assets/jiamengbiao.jpg" alt="" />
      </div>
    </div>
  </div>
</template>
<!-- 调用   <mycom></mycom> -->
<script>
import Header from "@/components/header/header.vue";
export default {
  components: { Header },
  data() {
    return {};
  },
};
</script>
<style lang="less" scoped>
.section1 {
  position: relative;
  width: 1140px;
  padding: 0 377px;
  height: 600px;
  background: linear-gradient(90deg, #fe992f, #ffc97e);
  font-weight: bold;
  .section1_box{
    position: relative;
    z-index: 4;
  }
  .section1_title {
    padding: 268px 0 0 0;
    font-size: 48px;
    font-weight: 400;
    color: #fefefe;
  }
  .section1_text {
    margin: 49px 0 45px;
    width: 656px;
    font-size: 24px;
    color: #fefefe;
  }
  .section1_btn {
    width: 216px;
    height: 40px;
    line-height: 40px;
    background: #ffd5a1;
    border-radius: 20px;
    padding: 0;
    border: 0;
    font-size: 24px;
    color: #fea645;
  }
  .section1_img {
    position: absolute;
    bottom: 0;
    right: 279px;
    z-index: 2;
  }
}
.jiameng {
  padding: 0 361px 268px;
  .jiameng_title {
    margin: 44px 0 26px;
    font-weight: bold;
    color: #333333;
    .span1 {
      font-size: 24px;
    }
    .span1 {
      font-size: 14px;
    }
  }
  .jiameng_image {
    width: 1200px;
    height: 959px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>