<template >
  <!-- vue实例外创建 -->
  <div class="header">
    <div class="header">
      <div class="layout">
        <div class="layout-logo">
          <img class="layout-logo-image" src="@/assets/1/logo.png" alt="" />
        </div>
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo header_nav"
          mode="horizontal"
          background-color="rgba(0,0,0,0)"
          text-color="#fff"
          active-text-color="#fff"
        >
          <el-menu-item index="1" @click="indexClick"> 网站首页 </el-menu-item>
          <el-menu-item index="2" @click="indexClick"> 公司简介 </el-menu-item>
          <el-menu-item index="3" @click="zixunClick()">
            加盟合作
          </el-menu-item>
          <el-menu-item index="4" @click="indexClick"> 联系我们 </el-menu-item>
          <el-menu-item index="3" @click="indexClick"> 新闻中心 </el-menu-item>
        </el-menu>
        <el-button class="header_btn">登录</el-button>
      </div>
    </div>
  </div>
</template>
<!-- 调用   <mycom></mycom> -->
<script>
export default {
  data() {
    return {
      activeIndex: "1",
    };
  },
  methods: {
    indexClick() {
      // this.$router.go(-1);
       this.$router.push("/pcHome");
    },
    zixunClick() {
      this.$router.push("/jiameng");
    },
  },
};
</script>
<style scoped lang="less">
/deep/.el-menu.el-menu--horizontal {
  border: none;
}
/deep/.el-menu-item {
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ffffff;
}
/deep/.el-menu--horizontal > .el-menu-item.is-active {
  border: 0;
  color: #fff;
}
/deep/ .el-menu--horizontal .el-menu-item:not(.is-disabled):focus,
/deep/ .el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
  color: #fff;
}
/deep/ .el-input__inner {
  height: 68px;
  border: none;
  font-size: 24px;
}
/deep/ .el-textarea {
  font-size: 24px;
}
</style>
<style lang="less" scoped>
.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 1860px;
  height: 160px;
  z-index: 99;
  .layout {
    display: flex;
    padding: 35px 0;
    align-items: center;
    justify-content: center;
    .layout-logo {
      margin-right: 90px;
      width: 272px;
      height: 91px;
      .layout-logo-image {
        width: 100%;
        height: 100%;
      }
    }
    .header_nav {
      margin-right: 81px;
      font-size: 24px;
      font-weight: bold;
      span {
        color: #ffffff;
      }
    }

    .header_btn {
      width: 152px;
      height: 57px;
      background: linear-gradient(0deg, #ff4e00, #ffa054);
      border: 0;
      font-size: 24px;
      font-weight: bold;
      color: #ffffff;
      border-radius: 50px;
    }
  }
  a {
    font-size: 24px;
  }
}
</style>